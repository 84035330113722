.filters, .salary-modifiers-filters
    padding 10px
    margin-bottom 30px
    h3
        margin-top 0
        margin-bottom: 15px;
    .field-wrapper
        margin-bottom 0
        label
            font-size 14px
            font-weight 400
            color $black
            margin-bottom 0
        input
            width auto
            height auto
        button
            margin-top 20px

    .sub-title
        margin-bottom 6px
        margin-top 4px