.thin-container
    max-width 770px
    width 100%
    margin 0 auto

.shadow-block
    //box-shadow 0 3px 5px rgba(0,0,0,.2)

.btn
    padding-top 9px
    padding-bottom 9px
    & > span
        margin-right 10px

section
    h1, h2
        text-align center
        margin 30px 0
    form
        padding 15px

.center
    text-align center

.logout-btn
    margin-top 11px
    padding 7px
    color #666
    margin-left 100px

.datepicker-dropdown
    z-index 9999 !important

.approved-status
    color #449D44

.declined-status
    color #C9302C

.pending-status
    color #31B0D5

.not-active
    pointer-events none
    cursor default
    color grey
