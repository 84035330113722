.field-group
    margin-bottom 15px
.sub-title, label
    font-size 16px
    color $gray
    font-weight 400
    margin-bottom 10px

.group-title
    font-size 25px
    margin 10px 0 30px 0

.field-wrapper
    display block
    margin-bottom 20px
    position relative
    label
        display block
        width 100%
    input, select, textarea
        width 100%
        border-radius 3px
        border 1px solid $gray-light
        padding 9px 12px
        margin-bottom 5px
        resize none
        &[disabled]
            color $gray-light
            background-color $gray-lighter
    input, select
        height 40px
    [type="checkbox"], [type="radio"]
        margin-right 5px
    button, a
        display block
        width 100%
    &.has-error
        label
            color $red
        input, textarea
            border-color $red
        ul.errorlist
            position absolute
            top 75px
            padding 0 12px
            font-size 11px
            color $red
            list-style none
    .file-input-wrapper
        max-width 500px
        margin 0 auto
        output
            padding-top 0
            ul
                list-style none
                padding 0
                li
                    margin-bottom 10px
                    .delete-button
                        display inline-block
                        width auto
                        margin-left 10px
                    a
                        display inline-block
                        &.file-link
                            width 70% !important
                        &.delete-file
                            width 25% !important
                            text-align right
        .remove-btn
            width auto
            display inline-block
            margin-bottom 15px
            display none
    .file-input-wrapper-one
        max-width 500px
        margin 0 auto
        output
            padding-top 0
            ul
                list-style none
                padding 0
                li
                    margin-bottom 10px
                    .delete-button
                        display inline-block
                        width auto
                        margin-left 10px
                    a
                        display inline-block
                        &.file-link
                            width 70% !important
                        &.delete-file
                            width 25% !important
                            text-align right
                            float right
            .remove-btn
                width auto
                display inline-block
                margin-bottom 15px
                display none
    .jq-select-multiple
        display none !important
    .multiselect-container
        .checkbox
            margin-top 0
        label
            color black
            margin-left 8px
            input
                margin-top: -8px
        .active
            label
                color white

.jq-file
    width 100%
    display block !important
    border-radius 3px
    margin 0 auto
    input
        height 100%
        line-height 1em
        cursor pointer
    &:hover .jq-file__browse
        background $green-darker
    &:active .jq-file__browse
        background $green-lighter

.jq-file__name
    display none

.jq-file__browse
    padding 10px 12px
    text-align center
    background-color $green
    color $white
    & > span
        margin-right 5px

.jq-file.disabled,
.jq-file.disabled .jq-file__name,
.jq-file.disabled .jq-file__browse
    border-color $gray-light
    background #F5F5F5
    box-shadow none
    color #888

.jq-selectbox
    width 100%
    vertical-align middle
    cursor pointer
    &.focused
        .jq-selectbox__select
            border 1px solid #5794BF
    &.disabled
        .jq-selectbox__select
            border-color $gray-light
            background $white
            color #888
    .placeholder
        color #888
    &:hover .jq-selectbox__trigger-arrow
        border-top-color #000
    &.disabled .jq-selectbox__trigger-arrow
        border-top-color #AAA

.jq-selectbox__select
    height 40px
    padding 8px 45px 8px 10px
    border 1px solid $gray-light
    border-radius 3px
    background $white
    color #333
    &:hover
        background #E6E6E6
    &:active
        background #F5F5F5
.jq-selectbox__select-text
    display block
    overflow hidden
    width 100%
    white-space nowrap
    text-overflow ellipsis

.jq-selectbox__trigger
    position absolute
    top 0
    right 0
    width 34px
    height 100%
    border-left 1px solid $gray-light

.jq-selectbox__trigger-arrow
    position absolute
    top 50%
    right 12px
    margin-top -2px
    width 0
    height 0
    border-top 5px solid #999
    border-right 5px solid transparent
    border-left 5px solid transparent

.jq-selectbox__dropdown
    box-sizing border-box
    width 100%
    margin 2px 0 0
    padding 0
    border 1px solid $gray-light
    border-radius 3px
    background $white


.jq-selectbox ul
    margin 0
    padding 0

.jq-selectbox li
    min-height 18px
    padding 5px 10px 6px
    user-select none
    white-space nowrap
    color #231F20
    .selected
        background-color #A3ABB1
        color $white
    &:hover
        background-color #08C
        color $white
    .disabled
        color #AAA
        &:hover
            background none
    &.optgroup
        font-weight bold
        &:hover
            background none
            color #231F20
            cursor default
    &.option
        padding-left 25px

.non-field-errors
    color red

.form-popup-buttons-wrapper
    margin-top 20px

.half-price-amount-wrapper
    color darkslategrey
    text-align center
    font-size 15px

    #half-price-amount
        font-weight bold

.is-non-jira-checkbox
    margin-bottom 10px

.disabled-jq-selectbox
    pointer-events none
    opacity 0.7

.modal-description-textarea
    height 150px

.working-off-dates-field
    margin-top 15px
    margin-bottom 15px

    ul.errorlist
        position static !important

.drag-and-drop-file-upload
    padding-top 4rem
    padding-bottom 4rem

    span
        font-size 4rem

.progress-bar
    width 0
