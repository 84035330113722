.table-wrapper
    .pretable
        margin-bottom 15px
        a
            display block
        .date
            font-size 20px
            padding 6px 0

    .table-employees-main, .table-expenses, .table-rewards, .table-salary-reviews
        table
            & > thead
                & > tr
                    & > th
                        font-weight bold
                        text-align center
                        //min-width 100px
                        max-width 80px
                        //position relative
                        padding 8px 8px

                        display table-cell
                        vertical-align middle
                        font-size 13px
                        cursor pointer
                        &.actions
                            min-width auto
                            width 80px
                        & > span
                            position absolute
                            top 50%
                            margin-top -8px
                            right 3px
                    .name-column
                            padding-right 40px
                            padding-left 40px
                            max-width none
                    .index-column
                        max-width 10px
                    .salary-type-column
                        width 80px
            & > tbody
                & > tr
                    & > td
                        display table-cell
                        vertical-align middle
                        text-align center

                        &.actions
                            a
                                display block
                                padding-left 10px
                                padding-right 0

                                .glyphicon-remove
                                    color $red

                                .glyphicon
                                    font-size 20px
                            a.remove
                                    padding-left 0
                                    padding-right 5px

    table
        & > thead
            & > tr
                & > th
                    // background-color $blue
                    // color $white
                    // font-weight 400
                    font-weight bold
                    text-align center
                    //min-width 100px TODO research why does this css property breaking markup of tables
                    position relative
                    padding 8px 22px
                    display table-cell
                    vertical-align middle
                    cursor pointer
                    &.actions
                        min-width auto
                        width 80px
                    & > span
                        position absolute
                        top 50%
                        margin-top -10px
                        right 5px
                        //&.glyphicon-sort
                        //    color #2d6ca2
                .name-column
                        padding-right 50px
                        padding-left 50px
                .index-column
                    max-width 10px
        & > tbody
            & > tr
                & > td
                    display table-cell
                    vertical-align middle
                    text-align center

                    &.actions
                        a
                            display block
                            padding-left 10px
                            padding-right 0

                            .glyphicon-remove
                                color $red

                            .glyphicon
                                font-size 20px
                        a.remove
                                padding-left 0
                                padding-right 5px

    .aftertable
        dl
            margin-bottom 15px
            dt, dd
                display block
                float left
                padding 0 10px
                width 50%
            dt
                text-align right

+below($min)
    .table-wrapper
        .aftertable
            dl
                dt, dd
                    width inherit

.working-off-days-table-detail
    margin-bottom 15px

.reward-actions
    min-width 100px

.employees-actions
    min-width 50px
    a
        .glyphicon-remove
            color $red

        .glyphicon
            font-size 20px

.add-employee-btn
    position relative
    left 270px
