.loader-wrapper
    width 100%
    height 100%
    position fixed
    display none
    top 0
    left 0
    z-index 9999
    .loader
        border: 16px solid #f3f3f3
        border-top: 16px solid #3498db
        border-bottom: 16px solid #3498db
        border-radius: 50%
        width: 120px
        height: 120px
        animation: spin 2s linear infinite
        position: absolute;
        top 50%
        left 50%

@keyframes spin
    0% { transform: rotate(0deg)}
    100% { transform: rotate(360deg)}
