.calc_create
    form
        .field-wrapper
            ul:not(.errorlist)
                padding 0
                list-style none
            input[type="radio"]
                height auto
                width auto

.employees-input-error
    color red
    margin-top 10px