.select2-container
    width 100% !important
.select2-container--default
    .select2-selection--single
        border-color $gray-light
        border-radius 3px
        height 40px
        .select2-selection__rendered
            padding-top 5px
            padding-bottom 5px
        .select2-selection__arrow
            height 40px
    .select2-selection--multiple
        border-color $gray-light
        .select2-selection__rendered
            padding 1.5px 12px !important
        .select2-selection__choice
            border-radius 3px
            background-color $gray-lighter
            border-color $gray-light
            margin-right 12px
            max-width 100%
            overflow hidden
            text-overflow ellipsis
    .select2-search--inline .select2-search__field
        height auto
    &.select2-container--focus .select2-selection--multiple
        border-color $gray-light
.select2-dropdown
    border-color $gray-light

.search-by-employee-input
    margin-top 14px
    //width 200px
.enable-pagination-checkbox
    margin-top 14px
    margin-left 20px

.search-vacation-request-by-employee-name-input, .search-by-employee-name-input
    margin-bottom 10px
    margin-top 20px
