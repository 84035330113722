.detail
    .detail-wrapper
        padding 15px
    .section-wrapper
        margin-bottom 20px
        .sub-title
            margin-bottom 0

.employee-photo
    height 400px
    width 380px

.copy-icon
    height 30px
    width 30px
    cursor pointer
    float left
    margin-right 5px

.copy-field-value
    margin-top 5px

.last-update
    font-size 17px

.notes
    border 0
    background-color transparent

.max-chars-span
    color rgba(255, 0, 0, 0.7)

.chars-left-counter
    margin-left 10px
