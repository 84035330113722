h1 {
  font-size: 32px;
  font-weight: 400;
}
.thin-container {
  max-width: 770px;
  width: 100%;
  margin: 0 auto;
}
.btn {
  padding-top: 9px;
  padding-bottom: 9px;
}
.btn > span {
  margin-right: 10px;
}
section h1,
section h2 {
  text-align: center;
  margin: 30px 0;
}
section form {
  padding: 15px;
}
.center {
  text-align: center;
}
.logout-btn {
  margin-top: 11px;
  padding: 7px;
  color: #666;
  margin-left: 100px;
}
.datepicker-dropdown {
  z-index: 9999 !important;
}
.approved-status {
  color: #449d44;
}
.declined-status {
  color: #c9302c;
}
.pending-status {
  color: #31b0d5;
}
.not-active {
  pointer-events: none;
  cursor: default;
  color: #808080;
}
.navbar-fixed-top {
  position: relative;
  font-size: 15px;
}
.navbar-fixed-top .username {
  color: #daa520 !important;
}
.navbar-brand {
  font-size: 20px;
  color: #daa520 !important;
}
.table-wrapper .pretable {
  margin-bottom: 15px;
}
.table-wrapper .pretable a {
  display: block;
}
.table-wrapper .pretable .date {
  font-size: 20px;
  padding: 6px 0;
}
.table-wrapper .table-employees-main table > thead > tr > th,
.table-wrapper .table-expenses table > thead > tr > th,
.table-wrapper .table-rewards table > thead > tr > th,
.table-wrapper .table-salary-reviews table > thead > tr > th {
  font-weight: bold;
  text-align: center;
  max-width: 80px;
  padding: 8px 8px;
  display: table-cell;
  vertical-align: middle;
  font-size: 13px;
  cursor: pointer;
}
.table-wrapper .table-employees-main table > thead > tr > th.actions,
.table-wrapper .table-expenses table > thead > tr > th.actions,
.table-wrapper .table-rewards table > thead > tr > th.actions,
.table-wrapper .table-salary-reviews table > thead > tr > th.actions {
  min-width: auto;
  width: 80px;
}
.table-wrapper .table-employees-main table > thead > tr > th > span,
.table-wrapper .table-expenses table > thead > tr > th > span,
.table-wrapper .table-rewards table > thead > tr > th > span,
.table-wrapper .table-salary-reviews table > thead > tr > th > span {
  position: absolute;
  top: 50%;
  margin-top: -8px;
  right: 3px;
}
.table-wrapper .table-employees-main table > thead > tr .name-column,
.table-wrapper .table-expenses table > thead > tr .name-column,
.table-wrapper .table-rewards table > thead > tr .name-column,
.table-wrapper .table-salary-reviews table > thead > tr .name-column {
  padding-right: 40px;
  padding-left: 40px;
  max-width: none;
}
.table-wrapper .table-employees-main table > thead > tr .index-column,
.table-wrapper .table-expenses table > thead > tr .index-column,
.table-wrapper .table-rewards table > thead > tr .index-column,
.table-wrapper .table-salary-reviews table > thead > tr .index-column {
  max-width: 10px;
}
.table-wrapper .table-employees-main table > thead > tr .salary-type-column,
.table-wrapper .table-expenses table > thead > tr .salary-type-column,
.table-wrapper .table-rewards table > thead > tr .salary-type-column,
.table-wrapper .table-salary-reviews table > thead > tr .salary-type-column {
  width: 80px;
}
.table-wrapper .table-employees-main table > tbody > tr > td,
.table-wrapper .table-expenses table > tbody > tr > td,
.table-wrapper .table-rewards table > tbody > tr > td,
.table-wrapper .table-salary-reviews table > tbody > tr > td {
  display: table-cell;
  vertical-align: middle;
  text-align: center;
}
.table-wrapper .table-employees-main table > tbody > tr > td.actions a,
.table-wrapper .table-expenses table > tbody > tr > td.actions a,
.table-wrapper .table-rewards table > tbody > tr > td.actions a,
.table-wrapper .table-salary-reviews table > tbody > tr > td.actions a {
  display: block;
  padding-left: 10px;
  padding-right: 0;
}
.table-wrapper .table-employees-main table > tbody > tr > td.actions a .glyphicon-remove,
.table-wrapper .table-expenses table > tbody > tr > td.actions a .glyphicon-remove,
.table-wrapper .table-rewards table > tbody > tr > td.actions a .glyphicon-remove,
.table-wrapper .table-salary-reviews table > tbody > tr > td.actions a .glyphicon-remove {
  color: #f00;
}
.table-wrapper .table-employees-main table > tbody > tr > td.actions a .glyphicon,
.table-wrapper .table-expenses table > tbody > tr > td.actions a .glyphicon,
.table-wrapper .table-rewards table > tbody > tr > td.actions a .glyphicon,
.table-wrapper .table-salary-reviews table > tbody > tr > td.actions a .glyphicon {
  font-size: 20px;
}
.table-wrapper .table-employees-main table > tbody > tr > td.actions a.remove,
.table-wrapper .table-expenses table > tbody > tr > td.actions a.remove,
.table-wrapper .table-rewards table > tbody > tr > td.actions a.remove,
.table-wrapper .table-salary-reviews table > tbody > tr > td.actions a.remove {
  padding-left: 0;
  padding-right: 5px;
}
.table-wrapper table > thead > tr > th {
  font-weight: bold;
  text-align: center;
  position: relative;
  padding: 8px 22px;
  display: table-cell;
  vertical-align: middle;
  cursor: pointer;
}
.table-wrapper table > thead > tr > th.actions {
  min-width: auto;
  width: 80px;
}
.table-wrapper table > thead > tr > th > span {
  position: absolute;
  top: 50%;
  margin-top: -10px;
  right: 5px;
}
.table-wrapper table > thead > tr .name-column {
  padding-right: 50px;
  padding-left: 50px;
}
.table-wrapper table > thead > tr .index-column {
  max-width: 10px;
}
.table-wrapper table > tbody > tr > td {
  display: table-cell;
  vertical-align: middle;
  text-align: center;
}
.table-wrapper table > tbody > tr > td.actions a {
  display: block;
  padding-left: 10px;
  padding-right: 0;
}
.table-wrapper table > tbody > tr > td.actions a .glyphicon-remove {
  color: #f00;
}
.table-wrapper table > tbody > tr > td.actions a .glyphicon {
  font-size: 20px;
}
.table-wrapper table > tbody > tr > td.actions a.remove {
  padding-left: 0;
  padding-right: 5px;
}
.table-wrapper .aftertable dl {
  margin-bottom: 15px;
}
.table-wrapper .aftertable dl dt,
.table-wrapper .aftertable dl dd {
  display: block;
  float: left;
  padding: 0 10px;
  width: 50%;
}
.table-wrapper .aftertable dl dt {
  text-align: right;
}
@media only screen and (max-width: 768px) {
  .table-wrapper .aftertable dl dt,
  .table-wrapper .aftertable dl dd {
    width: inherit;
  }
}
.working-off-days-table-detail {
  margin-bottom: 15px;
}
.reward-actions {
  min-width: 100px;
}
.employees-actions {
  min-width: 50px;
}
.employees-actions a .glyphicon-remove {
  color: #f00;
}
.employees-actions a .glyphicon {
  font-size: 20px;
}
.add-employee-btn {
  position: relative;
  left: 270px;
}
.field-group {
  margin-bottom: 15px;
}
.sub-title,
label {
  font-size: 16px;
  color: #666;
  font-weight: 400;
  margin-bottom: 10px;
}
.group-title {
  font-size: 25px;
  margin: 10px 0 30px 0;
}
.field-wrapper {
  display: block;
  margin-bottom: 20px;
  position: relative;
}
.field-wrapper label {
  display: block;
  width: 100%;
}
.field-wrapper input,
.field-wrapper select,
.field-wrapper textarea {
  width: 100%;
  border-radius: 3px;
  border: 1px solid #ccc;
  padding: 9px 12px;
  margin-bottom: 5px;
  resize: none;
}
.field-wrapper input[disabled],
.field-wrapper select[disabled],
.field-wrapper textarea[disabled] {
  color: #ccc;
  background-color: #eee;
}
.field-wrapper input,
.field-wrapper select {
  height: 40px;
}
.field-wrapper [type="checkbox"],
.field-wrapper [type="radio"] {
  margin-right: 5px;
}
.field-wrapper button,
.field-wrapper a {
  display: block;
  width: 100%;
}
.field-wrapper.has-error label {
  color: #f00;
}
.field-wrapper.has-error input,
.field-wrapper.has-error textarea {
  border-color: #f00;
}
.field-wrapper.has-error ul.errorlist {
  position: absolute;
  top: 75px;
  padding: 0 12px;
  font-size: 11px;
  color: #f00;
  list-style: none;
}
.field-wrapper .file-input-wrapper {
  max-width: 500px;
  margin: 0 auto;
}
.field-wrapper .file-input-wrapper output {
  padding-top: 0;
}
.field-wrapper .file-input-wrapper output ul {
  list-style: none;
  padding: 0;
}
.field-wrapper .file-input-wrapper output ul li {
  margin-bottom: 10px;
}
.field-wrapper .file-input-wrapper output ul li .delete-button {
  display: inline-block;
  width: auto;
  margin-left: 10px;
}
.field-wrapper .file-input-wrapper output ul li a {
  display: inline-block;
}
.field-wrapper .file-input-wrapper output ul li a.file-link {
  width: 70% !important;
}
.field-wrapper .file-input-wrapper output ul li a.delete-file {
  width: 25% !important;
  text-align: right;
}
.field-wrapper .file-input-wrapper .remove-btn {
  width: auto;
  display: inline-block;
  margin-bottom: 15px;
  display: none;
}
.field-wrapper .file-input-wrapper-one {
  max-width: 500px;
  margin: 0 auto;
}
.field-wrapper .file-input-wrapper-one output {
  padding-top: 0;
}
.field-wrapper .file-input-wrapper-one output ul {
  list-style: none;
  padding: 0;
}
.field-wrapper .file-input-wrapper-one output ul li {
  margin-bottom: 10px;
}
.field-wrapper .file-input-wrapper-one output ul li .delete-button {
  display: inline-block;
  width: auto;
  margin-left: 10px;
}
.field-wrapper .file-input-wrapper-one output ul li a {
  display: inline-block;
}
.field-wrapper .file-input-wrapper-one output ul li a.file-link {
  width: 70% !important;
}
.field-wrapper .file-input-wrapper-one output ul li a.delete-file {
  width: 25% !important;
  text-align: right;
  float: right;
}
.field-wrapper .file-input-wrapper-one output .remove-btn {
  width: auto;
  display: inline-block;
  margin-bottom: 15px;
  display: none;
}
.field-wrapper .jq-select-multiple {
  display: none !important;
}
.field-wrapper .multiselect-container .checkbox {
  margin-top: 0;
}
.field-wrapper .multiselect-container label {
  color: #000;
  margin-left: 8px;
}
.field-wrapper .multiselect-container label input {
  margin-top: -8px;
}
.field-wrapper .multiselect-container .active label {
  color: #fff;
}
.jq-file {
  width: 100%;
  display: block !important;
  border-radius: 3px;
  margin: 0 auto;
}
.jq-file input {
  height: 100%;
  line-height: 1em;
  cursor: pointer;
}
.jq-file:hover .jq-file__browse {
  background: #4bad4b;
}
.jq-file:active .jq-file__browse {
  background: #6cbf6c;
}
.jq-file__name {
  display: none;
}
.jq-file__browse {
  padding: 10px 12px;
  text-align: center;
  background-color: #5cb85c;
  color: #fff;
}
.jq-file__browse > span {
  margin-right: 5px;
}
.jq-file.disabled,
.jq-file.disabled .jq-file__name,
.jq-file.disabled .jq-file__browse {
  border-color: #ccc;
  background: #f5f5f5;
  box-shadow: none;
  color: #888;
}
.jq-selectbox {
  width: 100%;
  vertical-align: middle;
  cursor: pointer;
}
.jq-selectbox.focused .jq-selectbox__select {
  border: 1px solid #5794bf;
}
.jq-selectbox.disabled .jq-selectbox__select {
  border-color: #ccc;
  background: #fff;
  color: #888;
}
.jq-selectbox .placeholder {
  color: #888;
}
.jq-selectbox:hover .jq-selectbox__trigger-arrow {
  border-top-color: #000;
}
.jq-selectbox.disabled .jq-selectbox__trigger-arrow {
  border-top-color: #aaa;
}
.jq-selectbox__select {
  height: 40px;
  padding: 8px 45px 8px 10px;
  border: 1px solid #ccc;
  border-radius: 3px;
  background: #fff;
  color: #333;
}
.jq-selectbox__select:hover {
  background: #e6e6e6;
}
.jq-selectbox__select:active {
  background: #f5f5f5;
}
.jq-selectbox__select-text {
  display: block;
  overflow: hidden;
  width: 100%;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.jq-selectbox__trigger {
  position: absolute;
  top: 0;
  right: 0;
  width: 34px;
  height: 100%;
  border-left: 1px solid #ccc;
}
.jq-selectbox__trigger-arrow {
  position: absolute;
  top: 50%;
  right: 12px;
  margin-top: -2px;
  width: 0;
  height: 0;
  border-top: 5px solid #999;
  border-right: 5px solid transparent;
  border-left: 5px solid transparent;
}
.jq-selectbox__dropdown {
  box-sizing: border-box;
  width: 100%;
  margin: 2px 0 0;
  padding: 0;
  border: 1px solid #ccc;
  border-radius: 3px;
  background: #fff;
}
.jq-selectbox ul {
  margin: 0;
  padding: 0;
}
.jq-selectbox li {
  min-height: 18px;
  padding: 5px 10px 6px;
  user-select: none;
  white-space: nowrap;
  color: #231f20;
}
.jq-selectbox li .selected {
  background-color: #a3abb1;
  color: #fff;
}
.jq-selectbox li:hover {
  background-color: #08c;
  color: #fff;
}
.jq-selectbox li .disabled {
  color: #aaa;
}
.jq-selectbox li .disabled:hover {
  background: none;
}
.jq-selectbox li.optgroup {
  font-weight: bold;
}
.jq-selectbox li.optgroup:hover {
  background: none;
  color: #231f20;
  cursor: default;
}
.jq-selectbox li.option {
  padding-left: 25px;
}
.non-field-errors {
  color: #f00;
}
.form-popup-buttons-wrapper {
  margin-top: 20px;
}
.half-price-amount-wrapper {
  color: #2f4f4f;
  text-align: center;
  font-size: 15px;
}
.half-price-amount-wrapper #half-price-amount {
  font-weight: bold;
}
.is-non-jira-checkbox {
  margin-bottom: 10px;
}
.disabled-jq-selectbox {
  pointer-events: none;
  opacity: 0.7;
}
.modal-description-textarea {
  height: 150px;
}
.working-off-dates-field {
  margin-top: 15px;
  margin-bottom: 15px;
}
.working-off-dates-field ul.errorlist {
  position: static !important;
}
.drag-and-drop-file-upload {
  padding-top: 4rem;
  padding-bottom: 4rem;
}
.drag-and-drop-file-upload span {
  font-size: 4rem;
}
.progress-bar {
  width: 0;
}
.filters,
.salary-modifiers-filters {
  padding: 10px;
  margin-bottom: 30px;
}
.filters h3,
.salary-modifiers-filters h3 {
  margin-top: 0;
  margin-bottom: 15px;
}
.filters .field-wrapper,
.salary-modifiers-filters .field-wrapper {
  margin-bottom: 0;
}
.filters .field-wrapper label,
.salary-modifiers-filters .field-wrapper label {
  font-size: 14px;
  font-weight: 400;
  color: #000;
  margin-bottom: 0;
}
.filters .field-wrapper input,
.salary-modifiers-filters .field-wrapper input {
  width: auto;
  height: auto;
}
.filters .field-wrapper button,
.salary-modifiers-filters .field-wrapper button {
  margin-top: 20px;
}
.filters .sub-title,
.salary-modifiers-filters .sub-title {
  margin-bottom: 6px;
  margin-top: 4px;
}
.select2-container {
  width: 100% !important;
}
.select2-container--default .select2-selection--single {
  border-color: #ccc;
  border-radius: 3px;
  height: 40px;
}
.select2-container--default .select2-selection--single .select2-selection__rendered {
  padding-top: 5px;
  padding-bottom: 5px;
}
.select2-container--default .select2-selection--single .select2-selection__arrow {
  height: 40px;
}
.select2-container--default .select2-selection--multiple {
  border-color: #ccc;
}
.select2-container--default .select2-selection--multiple .select2-selection__rendered {
  padding: 1.5px 12px !important;
}
.select2-container--default .select2-selection--multiple .select2-selection__choice {
  border-radius: 3px;
  background-color: #eee;
  border-color: #ccc;
  margin-right: 12px;
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
}
.select2-container--default .select2-search--inline .select2-search__field {
  height: auto;
}
.select2-container--default.select2-container--focus .select2-selection--multiple {
  border-color: #ccc;
}
.select2-dropdown {
  border-color: #ccc;
}
.search-by-employee-input {
  margin-top: 14px;
}
.enable-pagination-checkbox {
  margin-top: 14px;
  margin-left: 20px;
}
.search-vacation-request-by-employee-name-input,
.search-by-employee-name-input {
  margin-bottom: 10px;
  margin-top: 20px;
}
.loader-wrapper {
  width: 100%;
  height: 100%;
  position: fixed;
  display: none;
  top: 0;
  left: 0;
  z-index: 9999;
}
.loader-wrapper .loader {
  border: 16px solid #f3f3f3;
  border-top: 16px solid #3498db;
  border-bottom: 16px solid #3498db;
  border-radius: 50%;
  width: 120px;
  height: 120px;
  animation: spin 2s linear infinite;
  position: absolute;
  top: 50%;
  left: 50%;
}
@-moz-keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
@-webkit-keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
@-o-keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.create form .checkbox-wrapper label,
.create form .checkbox-wrapper input {
  float: left;
  width: auto;
  height: auto;
}
.cancel-adding-btn {
  text-decoration: none !important;
}
.checkbox-wrapper-form {
  margin-bottom: 20px;
}
.detail .detail-wrapper {
  padding: 15px;
}
.detail .section-wrapper {
  margin-bottom: 20px;
}
.detail .section-wrapper .sub-title {
  margin-bottom: 0;
}
.employee-photo {
  height: 400px;
  width: 380px;
}
.copy-icon {
  height: 30px;
  width: 30px;
  cursor: pointer;
  float: left;
  margin-right: 5px;
}
.copy-field-value {
  margin-top: 5px;
}
.last-update {
  font-size: 17px;
}
.notes {
  border: 0;
  background-color: transparent;
}
.max-chars-span {
  color: rgba(255,0,0,0.7);
}
.chars-left-counter {
  margin-left: 10px;
}
.calc_create form .field-wrapper ul:not(.errorlist) {
  padding: 0;
  list-style: none;
}
.calc_create form .field-wrapper input[type="radio"] {
  height: auto;
  width: auto;
}
.employees-input-error {
  color: #f00;
  margin-top: 10px;
}
.birthdays-counter {
  font-size: 16px;
  margin-bottom: 20px;
}
.has-birthday {
  background: #dff0d8;
}
