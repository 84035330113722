.create
    form
        .checkbox-wrapper
            label, input
                float left
                width auto
                height auto

.cancel-adding-btn
    text-decoration none !important

.checkbox-wrapper-form
    margin-bottom 20px