.navbar-fixed-top
    position relative
    font-size 15px

    .username
        color goldenrod !important

.navbar-brand
    font-size 20px
    color goldenrod !important
